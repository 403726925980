@font-face {
  font-family: 'ms_sans_serif';
  src: url('./fonts/ms_sans_serif.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ms_sans_serif';
  src: url('./fonts/ms_sans_serif_bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
